import React from 'react'
import HeaderVideo from '../../assets/videos/iliad-epic-grow-header-video.mp4'
import HeaderVideoWebm from '../../assets/videos/iliad-epic-grow-header-video.webm'

const HeaderSection = ({ children, className }) => {
  return (
    <div className={className}>
      <video 
        autoPlay
        muted
        loop
        playsInline
        preload="none"
        style={{ objectFit: "cover", objectPosition: "bottom center", height: "100vh", minWidth: "100vw"}}
      >
        <source src={HeaderVideo} type="video/mp4" />
        <source src={HeaderVideoWebm} type="video/webm" />
        Your browser does not support the video tag.
      </video>
      {children}
    </div>
  )
}

export default HeaderSection
